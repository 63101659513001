<template>
	<div v-if="games.length > 0" style="display: flex; flex-direction: column; overflow: hidden">
		<div class="app-title" v-t="'featured'"></div>

		<div class="thumbnail-wrapper-grid">
			<div
				class="thumbnail-wrapper"
				v-for="(game, index) in games"
				:key="game.uid">

				<router-link
					tag="a"
					:to="'/play/' + game.uid"
					class="img_cover">
					<img
						:src="$util.bindUrlCdn(game.thumbnail, index === 0 ? 'd' : 'c300')"
						:alt="`thumbnail of game named ${game.name}`">
				</router-link>

			</div>
		</div>

		<div style="clear: both"></div>
	</div>
</template>

<script>
export default {
	name: 'component-games-list-featured',
	inject: ['$util'],
	props: {
		games: Array
	}
}
</script>

<style lang="scss" scoped>
.thumbnail-wrapper-grid {
	padding: 0 1em 1em 1em;
}
</style>
