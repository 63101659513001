<template>
	<main>
		<div v-if="isLoading" class="preloader"></div>

		<component-games-list-slick :games="get_content_banner"/>
		<component-games-list-featured :games="get_content_featured"/>
		<component-games-list-slider
			:title="'popular'"
			:slidesPerView="3"
			:games="get_content_popular"/>
		<component-games-list-slider
			v-for="(genre, index) in get_landing_genre_content"
			:key="index"
			:title="`genre.${genre.uid}`"
			:urlMore="`/genre/${genre.uid}`"
			:games="genre.content"/>
	</main>
</template>

<script>
import ComponentGamesListSlick from '@/components/component-games-list-slick'
import ComponentGamesListSlider from '@/components/component-games-list-slider'
import ComponentGamesListFeatured from '@/components/component-games-list-featured'

export default {
	name: 'landing',
	inject: ['$util'],
	data () {
		return {
			isLoading: true,
			get_content_banner: [],
			get_content_featured: [],
			get_content_popular: [],
			get_landing_genre_content: []
		}
	},
	components: {
		ComponentGamesListSlick,
		ComponentGamesListSlider,
		ComponentGamesListFeatured
	},
	methods: {
		async getGames () {
			const payloadGames = [{
				game: [
					{ get_content_featured: [{ args: [{ limit: 6 }] }] },
					{ get_content_banner: [{ args: [{ limit: 5 }] }] },
					{ get_content_popular: [{ args: [{ limit: 10 }] }] },
					{ get_landing_genre_content: [{ args: [{ limit_genre: 6, limit_content: 4 }] }] }
				]
			}]

			const responses = await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: payloadGames
			})

			this.isLoading = false

			for (const type in responses.game) {
				const response = responses.game[type][0]

				if (response.length === 1) {
					const result = response[0]

					if (result.code === 2001) {
						this[type] = result.data
					}
				} else {
					for (const indexResponse in response) {
						const result = response[indexResponse]

						if (result.code === 2001) {
							this[type].push(result.data)
						}
					}
				}
			}
		}
	},
	async created () {
		this.getGames()
	}
}
</script>

<style lang="scss" scoped>
main {
	& > * {
		background-color: $color-background;
		margin-bottom: .5em;
	}

	/deep/ {
		.app-title {
			padding: 1em;
			font-weight: 600;
			text-align: center;
			justify-content: center;
			text-transform: uppercase;

			.more {
				font-weight: inherit;
				text-transform: lowercase;
				color: $color-accent-pink;

				&, & > *:first-child {
					@extend %text-trim;
				}
			}
		}

		.banner {
			line-height: 0;
		}

		.banner > * {
			padding: 0 .6em;
		}

		.banner .img_cover,
		.thumbnail-wrapper .img_cover {
			border-radius: .6em;
			background-color: rgba(128, 128, 128, .1); /* debug: if no image */
		}

		.thumbnail-wrapper {
			display: inline-block;
			padding: .3em;
			width: 100%;
			vertical-align: top;
			line-height: 0;
			overflow: hidden;
		}

		.thumbnail-wrapper-grid {
			margin: -.3em;
		}

		.thumbnail-wrapper-grid .thumbnail-wrapper {
			width: 33.33%;
		}

		@media (max-width: 768px) {
			.thumbnail-wrapper-grid .thumbnail-wrapper:first-of-type {
				padding-bottom: 0;
				width: 66.66%;
				float: left;
			}
		}

		.thumbnail-wrapper-text {
			overflow-x: auto;
			overflow-y: hidden;
			white-space: nowrap;
		}

		.thumbnail-wrapper-text .thumbnail-wrapper {
			width: 10em;
			line-height: 1.3;
			white-space: normal;
		}

		.thumbnail-wrapper-text .thumbnail-title {
			font-weight: 700;
		}

		.thumbnail-wrapper-text .thumbnail-wrapper > .img_cover {
			box-shadow: 0 0 3px rgba(0, 0, 0, .1)
		}

		.thumbnail-wrapper-text .thumbnail-wrapper > *:not(.img_cover) {
			margin-top: .6em;
		}
	}
}
</style>
