<template>
	<div v-if="games.length > 0">
		<slick
			ref="slick"
			:options="slickOptions"
			:key="slickOptions.rtl">
			<router-link
				tag="a"
				v-for="game in games"
				:key="game.uid"
				:to="'/play/' + game.uid"
				:style="{ 'background-image': 'url(' + $util.bindUrlCdn(game.banner_images) + ')' }"
				:alt="`thumbnail of game named ${game.name}`">
			</router-link>
		</slick>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Slick from 'vue-slick'
import '@/../node_modules/slick-carousel/slick/slick.css'

export default {
	name: 'component-slider-banner',
	inject: ['$util'],
	components: {
		Slick
	},
	props: {
		games: Array
	},
	computed: {
		...mapState('ui', {
			modalIsOpen: state => state.modal.isOpen
		}),
		...mapState('i18n', {
			rtl: state => state.direction === 'rtl'
		})
	},
	data () {
		return {
			slickOptions: {
				slidesToShow: 1,
				dots: true,
				prevArrow: false,
				nextArrow: false,
				variableHeight: true,
				touchThreshold: 10,
				dotsClass: 'slick-dots-banner',
				appendDots: '.slick-list',
				autoplay: true,
				autoplaySpeed: 5000,
				rtl: false
			}
		}
	},
	watch: {
		'modalIsOpen' (state) {
			if (!state && Object.keys(this.$refs).length > 0) {
				this.$refs.slick.setPosition()
			}
		},
		rtl: {
			handler (state) {
				if (state !== this.slickOptions.rtl) {
					this.slickOptions.rtl = state
				}
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
.slick-slide a {
	height: 0;
	padding-bottom: 56.25%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.slick-slide a:focus {
	outline: 0
}
</style>

<style>
.slick-list {
	line-height: 0
}
.slick-list .slick-dots-banner {
	position: absolute;
	bottom: .5em;
	width: 100%;
	text-align: center;
}
.slick-list .slick-dots-banner li {
	display: inline-block;
}
.slick-list .slick-dots-banner li:not(.slick-active) {
	opacity: .5;
}
.slick-list .slick-dots-banner button {
	padding: 0;
	margin: .5em .25em;
	width: .75em;
	height: .75em;
	border-radius: 50%;
	color: transparent;
	background-color: white;
	line-height: 0;
	overflow: hidden;
	cursor: pointer;
}
</style>
