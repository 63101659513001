<template>
	<div v-if="games.length > 0" style="overflow: hidden">
		<div class="app-title f-list">
			<div
				v-if="urlMore"
				class="more f-list f-list-right text-sub"
				style="opacity: 0 !important; pointer-events: none !important">
				<div v-t="'more'"></div>
				<div>
					<i class="material-icons" v-if="$store.state.i18n.direction === 'rtl'"> navigate_before </i>
					<i class="material-icons" v-else> navigate_next </i>
				</div>
			</div>

			<div v-t="title" style="flex-grow: 1"></div>

			<router-link
				v-if="urlMore"
				:to="urlMore"
				tag="a"
				class="more f-list f-list-right text-sub">
				<div v-t="'more'"></div>
				<div>
					<i class="material-icons" v-if="$store.state.i18n.direction === 'rtl'"> navigate_before </i>
					<i class="material-icons" v-else> navigate_next </i>
				</div>
			</router-link>
		</div>

		<section>
			<router-link
				tag="a"
				v-for="game in games"
				:key="game.uid"
				:to="'/play/' + game.uid"
				:style="{ maxWidth: `${100 / slidesPerView - 5 / slidesPerView}%` }">
				<div class="img_cover">
					<img
						:src="$util.bindUrlCdn(game.thumbnail, 'c300')"
						:alt="`thumbnail of game named ${game.name}`">
				</div>
				<div class="game-description">{{ game.name }}</div>
			</router-link>
		</section>
	</div>
</template>

<script>
export default {
	name: 'component-games-list-slider',
	inject: ['$util'],
	props: {
		games: Array,
		title: String,
		urlMore: String,
		slidesPerView: Number
	}
}
</script>

<style lang="scss" scoped>
$slides-per-view: 2;
$slide-gap: .6em;

/deep/ section {
	display: flex;
	margin: 0 (-$slide-gap);
	padding: 1em;
	padding-top: 0;
	overflow-x: auto;
	overflow-y: hidden;

	// fix flex missing right padding when overflow
	&:after {
		content: '';
		padding-right: inherit;
		flex-shrink: 0;
	}

	> * {
		padding: 0 $slide-gap;
		width: 100%;
		max-width: 100% / $slides-per-view - 5% / $slides-per-view;
		flex-grow: 1;
		flex-shrink: 0;
	}

	.img_cover {
		line-height: 0;

		img { border-radius: .5em }
	}
}

.game-description {
	@extend %text-sub;
	margin-top: $slide-gap;

	color: $color-accent-pink;
	font-weight: 600;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
